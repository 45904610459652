
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import QuickSearchItem from '@/models/metrics/quickSearchItem';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import {
  CompositeWidgetDefinition,
  CompositeWidgetEntry,
  WidgetEntry,
} from '@/models/Charts/compositeWidgetDefinition';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';
import { indexedWidgetDefinitions } from '@/utils/misc';

@Component({
  components: {
    WidgetContainer,
    LayoutGrid,
  },
})
export default class DetailedCustomerView extends Vue {
  @Prop()
  private selectedTimeFilter!: [string, string];

  @Prop()
  private selectedDateGrouping!: FilterTimeAxisSpanEnum;

  @Prop()
  private selection!: QuickSearchItem;

  // Needed by Chart when opening from a double click on a chart
  @Prop()
  private onClose?: () => void;

  private widgetDefinitions: WidgetEntry[] = [];
  private gridLayout = availableDashboardLayouts[DashboardLayout.TwoByThree];

  private modalOpen = true;
  private loading = false;

  private created() {
    this.widgetDefinitions = this.createWidgetDefinitions();
  }

  @Watch('modalOpen')
  private onModalOpenChanged() {
    if (!this.modalOpen && !!this.onClose) {
      this.onClose();
    }
  }

  private createWidgetDefinitions(): WidgetEntry[] {
    return indexedWidgetDefinitions([
      new WidgetDefinition(
        WidgetEnum.QuotesTopQuotedMaterialsTimeline,
        [],
        this.selectedTimeFilter,
        [],
        undefined,
        this.selectedDateGrouping,
      ),
      new CompositeWidgetDefinition(
        new CompositeWidgetEntry(
          new WidgetDefinition(
            WidgetEnum.QuotesMap,
            [],
            this.selectedTimeFilter,
            [],
            undefined,
            this.selectedDateGrouping,
          ),
          'tag',
        ),
        new CompositeWidgetEntry(
          new WidgetDefinition(
            WidgetEnum.QuotesConvertedMap,
            [],
            this.selectedTimeFilter,
            [],
            undefined,
            this.selectedDateGrouping,
          ),
          'check',
        ),
      ),
      new WidgetDefinition(
        WidgetEnum.QuotesConversionRatesGauge,
        [],
        this.selectedTimeFilter,
        [],
        undefined,
        this.selectedDateGrouping,
      ),
      new WidgetDefinition(
        WidgetEnum.QuotesCustomerEvolution,
        [],
        this.selectedTimeFilter,
        [],
        undefined,
        this.selectedDateGrouping,
      ),
      new WidgetDefinition(
        WidgetEnum.QuotesFiguresDetailed,
        [],
        this.selectedTimeFilter,
        [],
        undefined,
        this.selectedDateGrouping,
      ),
      new WidgetDefinition(
        WidgetEnum.QuotesTopSalespeopleDetailed,
        [],
        this.selectedTimeFilter,
        [],
        undefined,
        this.selectedDateGrouping,
      ),
    ]);
  }

  private updateWidgetDefinition(index: number, widgetDefinition: WidgetDefinition) {
    this.$set(this.widgetDefinitions, index, widgetDefinition);
  }

  private getWidgetHelpPosition(index: number): 'is-bottom-left' | 'is-bottom-right' {
    return index === 2 || index === 5 ? 'is-bottom-left' : 'is-bottom-right';
  }
}
